import React from 'react';
import {BiHomeAlt, BiUser} from 'react-icons/bi';
import {BsBriefcase, BsChatSquareText} from 'react-icons/bs';
import {FaCode} from "react-icons/fa";
import {Link} from 'react-scroll';


const Nav = () => {
    return <nav className='fixed bottom-2 lg:bottom-8 w-full overflow-hidden z-50'>
        <div className='container mx-auto'>
            <div className='w-full bg-black/20 h-[96px] backdrop-blur-2xl rounded-full max-w-[460px] mx-auto px-5 flex
      justify-between text-2xl text-white/50 items-center'>
                <Link
                    to='home'
                    smooth={true}
                    spy={true}
                    activeClass='active' className='cursor-pointer w-[60px] h-[60px] flex items-center justify-center'>
                    <BiHomeAlt/>
                </Link>
                <Link
                    to='about'
                    activeClass='active'
                    smooth={true}
                    spy={true}
                    className='cursor-pointer w-[60px] h-[60px] flex items-center justify-center'>
                    <BiUser/>
                </Link>
                <Link
                    to='technologies'
                    activeClass='active'
                    smooth={true}
                    spy={true}
                    className='cursor-pointer w-[60px] h-[60px] flex items-center justify-center'>
                    <FaCode/>
                </Link>
                <Link
                    to='work'
                    activeClass='active'
                    smooth={true}
                    spy={true}
                    className='cursor-pointer w-[60px] h-[60px] flex items-center justify-center'>
                    <BsBriefcase/>
                </Link>
                <Link
                    to='contact'
                    activeClass='active'
                    smooth={true}
                    spy={true}
                    className='cursor-pointer w-[60px] h-[60px] flex items-center justify-center'>
                    <BsChatSquareText/>
                </Link>
            </div>
        </div>
    </nav>;
};

export default Nav;
